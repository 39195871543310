import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getSubdomain, getDefaultPath } from '../utils/common';
import { check_login } from "../utils/login";

const PublicRoute = ({ component: Component, ...rest }) => {
    const subdomain = getSubdomain();
    const isLoggedIn = check_login();
    return (
        <Route
            {...rest}
            render={props => {
                if(!subdomain) {
                    return <Component {...props} />
                } else {
                    return (
                        <Redirect to={ isLoggedIn ? getDefaultPath() : 
                        "/login" } />
                    )
                }
            }}
        />
    )
}

export default PublicRoute;