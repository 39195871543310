import { useEffect, useState } from "react";
import { check_domain } from "../utils/auth";
import { getSubdomain } from "../utils/common";

const useGlobalState = () => {
    const [state, setState] = useState({
        plan: -1,
        addons: [],
        organizationName: "",
        settingsVisible: false,
        isDetailsRequested: false
    });
    const subdomain = getSubdomain();
    
    useEffect(() => {
        const checkSubdomain = async () => {
            if (subdomain) {
                const data = await check_domain();
                setState(prev => ({
                    ...prev,
                    plan: data.plan,
                    addons: data.addons,
                    organizationName: data.organization_name,
                    isDetailsRequested: data.is_details_requested
                }));
            }
        }
        checkSubdomain();
    }, [subdomain]);
    
    const actions = (action) => {
        const { type, payload } = action;

        switch (type) {
            case "setState" :
                return setState(payload);
            case "showSettings":
                return setState(prev => ({ ...prev, settingsVisible: true }));
            case "hideSettings":
                return setState(prev => ({ ...prev, settingsVisible: false }));
            default :
                return state;
        }
    }

    return { state, actions };
}

export default useGlobalState;