import { __setCookie, __deleteCookie, __getCookie } from "./cookie";
import keys from "../config/keys";
import { G_API_URL, G_URL } from "../constants/constants";
import CryptoJS from "crypto-js";
import axios from "axios";
import queryString from "query-string";

const login_user = (token) => {
    if (token !== undefined) {
        __setCookie(keys.cookiePrefix + "ut", token, 1, "day");
    }
};

const check_login = () => {
    const token_cookie = __getCookie(keys.cookiePrefix + "ut");
    return (
        token_cookie.cookieExists &&
        token_cookie.cookieValue !== undefined &&
        token_cookie.cookieValue !== null
    );
};

const logout_user = () => {
    let cookies = document.cookie.split(";");
    cookies.map((cookie) => __deleteCookie(cookie.split("=")[0]));
    localStorage.removeItem("Nocookie");
    // Clear Storage
    localStorage.clear();
    sessionStorage.clear();
};

const encHexSalt = (content) => {

    let encContent = {
        hex: "",
        salt: 0
    };

    let content_B64 = CryptoJS.AES.encrypt(content, "BSLDFS");
    // let content_E64 = CryptoJS.enc.Base64.parse(content_B64);
    // let content_hex = content_E64.toString(CryptoJS.enc.Hex);
    encContent.hex = content_B64.ciphertext.toString();
    encContent.salt = content_B64.salt.toString();
    return encContent;
};

const autoLogin = (emailEncryptedKey, rurl, setEnrollModal, is_ignite, scheduleWorkshop = false, slug = "") => {
    const data = {
        encIK: emailEncryptedKey
    }

    // Redirect based on rurl
    let redUrl = rurl !== undefined ? rurl : '';

    axios.post(G_API_URL + "auth/token", queryString.stringify(data))
        .then(res => {
            if (res.data.status === 1) {
                const { lastActive, accountVerified } = res.data.data;
                // Remove encIK from store
                localStorage.removeItem('encIK');
                // Set First Login status based on lastActive
                if (lastActive === 1) {
                    localStorage.setItem('first-log', "true");
                }
                // Login user
                login_user(res.data.data);

                if (accountVerified) {
                    if (is_ignite === 'ignite_modal') {
                        setEnrollModal(false);
                        window.location.href = G_URL + "ignite/redr";
                    } else {
                        // Check if its payment RURL
                        let rurlCheck = redUrl.split('/')[0];
                        if (rurlCheck === 'payment') {
                            setTimeout(() => {
                                window.location.href = decodeURIComponent(G_URL + `${redUrl !== '' ? redUrl : ''}`);
                            }, 500);
                        } else {
                            // const searchQuery = localStorage.getItem('utmQuery');
                            // const searchQuery = __getCookie(keys.cookiePrefix + "utm").cookieValue;
                            setTimeout(() => {
                                window.location.href = decodeURIComponent(G_URL + "onboard/");
                            }, 500);
                        }
                    }
                } else {
                    window.location.href = G_URL + `activation/mobile?rurl=${redUrl}`;
                }
            } else {
                window.location.href = G_URL + `login?rurl=${redUrl}`;
            }
        })
        .catch(err => {
            console.log(err);
            window.location.href = G_URL + `login?rurl=${redUrl}`;
        })
}

export { login_user, check_login, logout_user, encHexSalt, autoLogin };
