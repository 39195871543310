import { check_login, logout_user } from "../utils/login";
import { __getUserType } from "../utils/user-details";

const MobileViewValidator = (props) => {
    const isLoggedIn = check_login();
    // Check if user is logged in and if user is not student
    if (isLoggedIn && __getUserType() !== 6) {
        return (
            <div className="mobileviewvalidator-wrapper">
                <div className="mobileview-wrapper w-screen h-screen bg-aluminium text-lg font-bold fixed top-0 left-0 flex text-center flex-col p-4 justify-center items-center lg:hidden">
                    For staff members, quizr can only be accessed on a computer/PC.
                    <div className="default-green-btn filled-green mt-4" onClick={() => { logout_user(); window.location.href = '/'; }}>Log out</div>
                </div>
                <div className="desktopview-wrapper hidden lg:block">
                    {props.children}
                </div>
            </div>
        )
    } else {
        return props.children
    }
}

export default MobileViewValidator;