import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from 'history';
import { lazy } from '@loadable/component';

import "antd/dist/antd.css";
import "./custom-icons.css";
import './index.css';

import * as serviceWorker from './serviceWorker';
import ScrollToTop from './utils/scrollToTop';
import Spinner from './components/Spinner/Spinner';
import PublicRoute from './routes/PublicRoute';
import ProtectedRoute from './routes/ProtectedRoute';
import MobileViewValidator from './routes/MobileViewValidator';
import Context from './store/context';
import useGlobalState from './store/useGlobalState';

const Home = lazy(() => import('./pages/Home/Home'));
const Default403 = lazy(() => import('./pages/Default/Default403'));
const Default404 = lazy(() => import('./pages/Default/Default404'));
const Login = lazy(() => import('./pages/Authentication/Login/Login'));
const Overview = lazy(() => import("./pages/Overview/Overview"));
const Assessments = lazy(() => import("./pages/Admin/Assessments/Assessments"));
const QuestionBank = lazy(() => import("./pages/Admin/QuestionBank/QuestionBank"));
const QuestionCreator = lazy(() => import("./pages/Admin/QuestionCreator/QuestionCreator"));
const TestCreator = lazy(() => import("./pages/Admin/TestCreator/TestCreator"));
const Reports = lazy(() => import("./pages/Admin/Reports/Reports"));
const PerformanceSummary = lazy(() => import("./pages/Common/PerformanceSummary"));
const DetailedReport = lazy(() => import("./pages/Common/DetailedReport"));
const Catalog = lazy(() => import("./pages/Admin/Catalog/Catalog"));
const Assignment = lazy(() => import("./pages/Admin/Assignment/Assignment"));
const Users = lazy(() => import("./pages/Admin/Users/Users"));
const DomainManagement = lazy(() => import("./pages/Admin/DomainManagement/DomainManagement"));
const TestCatalogs = lazy(() => import("./pages/Admin/TestCatalogs/TestCatalogs"));
const QuestionAccess = lazy(() => import("./pages/Admin/QuestionAccess/QuestionAccess"));
const StudentGroups = lazy(() => import("./pages/Admin/StudentGroups/StudentGroups"));
const UserAttributes = lazy(() => import("./pages/Admin/UserAttributes/UserAttributes"));
const Billing = lazy(() => import("./pages/Admin/Billing/Billing"));
const StudentDashboard = lazy(() => import("./pages/StudentDashboard/StudentDashboard"));
const Signup = lazy(() => import("./pages/Authentication/Signup/Signup"));
const VivaVoce = lazy(() => import("./pages/Admin/VivaVoce/VivaVoce"));
const RemoteInterviews = lazy(() => import("./pages/Admin/RemoteInterviews/RemoteInterviews"));
const CodeLabs = lazy(() => import("./pages/Admin/CodeLabs/CodeLabs"));
const SuperProctor = lazy(() => import("./pages/Admin/SuperProctor/SuperProctor"));
const ManualEval = lazy(() => import("./pages/Admin/ManualEval/ManualEval"));
const Invitation = lazy(() => import("./pages/Student/Assessments/Invitation"));
const UserDetails = lazy(() => import("./pages/Common/UserDetails"));
const CollatedReports = lazy(() => import("./pages/Admin/CollatedReports/CollatedReports"));

const history = createBrowserHistory();

function App() {
    const store = useGlobalState();
    return (
        <Context.Provider value={store}>
            <Suspense fallback={<Spinner />}>
                <Switch history={history}>
                    <PublicRoute exact path={`${process.env.PUBLIC_URL}/`} component={Home} />
                    <Route exact path={`${process.env.PUBLIC_URL}/login`} component={Login} />
                    <Route exact path={`${process.env.PUBLIC_URL}/signup`} component={Signup} />
                    <Route exact path={`${process.env.PUBLIC_URL}/details`} component={UserDetails} />
                    <MobileViewValidator>
                        <ProtectedRoute authorize={[3]} exact path={`${process.env.PUBLIC_URL}/overview`} component={Overview} />
                        <ProtectedRoute authorize={[3,8]} exact path={`${process.env.PUBLIC_URL}/assessments`} component={Assessments} />
                        <ProtectedRoute authorize={[3,8]} exact path={`${process.env.PUBLIC_URL}/question-bank`} component={QuestionBank} />
                        <ProtectedRoute authorize={[3,8]} exact path={`${process.env.PUBLIC_URL}/question-creator`} component={QuestionCreator} />
                        <ProtectedRoute authorize={[3,8]} exact path={`${process.env.PUBLIC_URL}/test-creator`} component={TestCreator} />
                        <ProtectedRoute authorize={[3,5,8]} exact path={`${process.env.PUBLIC_URL}/reports`} component={Reports} />
                        <ProtectedRoute authorize={[3,5,6,8]} exact path={`${process.env.PUBLIC_URL}/performance-summary`} component={PerformanceSummary} />
                        <ProtectedRoute authorize={[3,5,6,8]} redr={true} exact path={`${process.env.PUBLIC_URL}/detailed-report`} component={DetailedReport} />
                        <ProtectedRoute authorize={[3,8]} exact path={`${process.env.PUBLIC_URL}/catalog`} component={Catalog} />
                        <ProtectedRoute authorize={[3,8]} exact path={`${process.env.PUBLIC_URL}/assessments/assign`} component={Assignment} />
                        <ProtectedRoute authorize={[3]} exact path={`${process.env.PUBLIC_URL}/users`} component={Users} />
                        <ProtectedRoute authorize={[3,8]} exact path={`${process.env.PUBLIC_URL}/student-groups`} component={StudentGroups} />
                        <ProtectedRoute authorize={[3]} exact path={`${process.env.PUBLIC_URL}/user-attributes`} component={UserAttributes} />
                        <ProtectedRoute authorize={[3,9]} exact path={`${process.env.PUBLIC_URL}/billing`} component={Billing} />
                        <ProtectedRoute authorize={[3,8]} exact path={`${process.env.PUBLIC_URL}/viva-voce`} component={VivaVoce} />
                        <ProtectedRoute authorize={[3,8]} exact path={`${process.env.PUBLIC_URL}/remote-interviews`} component={RemoteInterviews} />
                        <ProtectedRoute authorize={[3,8]} exact path={`${process.env.PUBLIC_URL}/code-labs`} component={CodeLabs} />
                        <ProtectedRoute authorize={[3,8]} exact path={`${process.env.PUBLIC_URL}/super-proctor`} component={SuperProctor} />
                        <ProtectedRoute authorize={[3,8]} exact path={`${process.env.PUBLIC_URL}/manual-evaluation`} component={ManualEval} />
                        <ProtectedRoute authorize={[3,8]} exact path={`${process.env.PUBLIC_URL}/collated-reports`} component={CollatedReports} />
                        <ProtectedRoute authorize={[3]} is_super={true} exact path={`${process.env.PUBLIC_URL}/domain-management`} component={DomainManagement} />
                        <ProtectedRoute authorize={[3]} is_super={true} exact path={`${process.env.PUBLIC_URL}/test-catalogs`} component={TestCatalogs} />
                        <ProtectedRoute authorize={[3]} is_super={true} exact path={`${process.env.PUBLIC_URL}/question-access`} component={QuestionAccess} />
                        <ProtectedRoute authorize={[6]} exact path={`${process.env.PUBLIC_URL}/dashboard/`} component={StudentDashboard} />
                        <ProtectedRoute authorize={[6]} exact path={`${process.env.PUBLIC_URL}/assessment/invitation`} component={Invitation} />
                    </MobileViewValidator>
                    <Route exact path={`${process.env.PUBLIC_URL}/forbidden/`} component={Default403} />
                    <Route exact component={Default404} />
                </Switch>
            </Suspense>
        </Context.Provider>
    );
}

ReactDOM.render(
    <Router>
        <ScrollToTop />
        <App />
    </Router>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();