import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { getSubdomain, getDefaultPath } from '../utils/common';
import { check_login } from "../utils/login";
import { __getUserType } from "../utils/user-details";
import Context from "../store/context";

const ProtectedRoute = (props) => {
    const { authorize, redr, is_super, component: Component, ...rest } = props;
    const subdomain = getSubdomain();
    const isLoggedIn = check_login();
    const userType = __getUserType();
    const { state } = useContext(Context);
    const { isDetailsRequested } = state;

    const getRurl = () => {
        if(redr) {
            const path = window.location.pathname;
            const searchParams = window.location.search;
            return "?rurl=" + encodeURIComponent(path + searchParams);
        }
        return "";
    }

    return (
        <Route
            {...rest}
            render={props => {
                if(subdomain) {
                    if(isLoggedIn) {
                        if(isDetailsRequested) {
                            return <Redirect to={"/details" + getRurl()} />;
                        } else if(authorize.includes(userType)) {
                            if(is_super && subdomain !== "super") {
                                return <Redirect to={getDefaultPath()} />
                            }
                            return <Component {...props} />
                        }
                        return <Redirect to={getDefaultPath()} />
                    }
                    return <Redirect to={"/login" + getRurl()} />;
                } 
                return <Redirect to="/" />;
            }}
        />
    )
}

export default ProtectedRoute;