export const ICONS = {
    BRAND_ICON: "../assets/icons/svg_icons/logo_web.svg",
};

export const G_URL = process.env.REACT_APP_G_URL ?? "";
export const G_PLAT_API_URL = process.env.REACT_APP_G_PLAT_API_URL ?? "";
export const G_API_URL = process.env.REACT_APP_G_API_URL ?? "";
export const G_NODE_API_URL = process.env.REACT_APP_G_NODE_API_URL ?? "";
export const G_CRON_NODE_API_URL = process.env.REACT_APP_G_CRON_NODE_API_URL ?? "";
export const G_FG_API_URL = process.env.REACT_APP_G_FG_API_URL ?? "";
export const G_DOMAIN = process.env.REACT_APP_G_DOMAIN ?? "";
export const G_COOKIE_PREFIX = process.env.REACT_APP_G_COOKIE_PREFIX ?? "";
export const G_APP_PLATFORM = process.env.REACT_APP_PLATFORM ?? "";
export const G_PORTAL_DOMAIN = process.env.REACT_APP_G_PORTAL_DOMAIN ?? "";
export const G_TEST_DOMAIN = process.env.REACT_APP_G_TEST_DOMAIN ?? "";

export const G_S3_URL = process.env.REACT_APP_G_S3_URL ?? "";
export const ENTITY_ID = "VS_75ca2f64f41f71";
